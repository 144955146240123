import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a14af81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ef-select" }
const _hoisted_2 = {
  key: 0,
  class: "ef-select__label"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['ef-select__input', { '-isOpen': _ctx.isOpen }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(['ef-select__placeholder', { '-fill': _ctx.label }])
      }, _toDisplayString(_ctx.placeholder) + " " + _toDisplayString(_ctx.required ? "*" : ""), 3),
      (_ctx.choiceSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helperMsg, (msg, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          class: "ef-select__helper"
        }, " *" + _toDisplayString(msg), 1))
      }), 128)),
      _createElementVNode("div", {
        class: _normalizeClass(['ef-select__arrow', { '-isOpen': _ctx.isOpen }])
      }, null, 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['ef-select__choices', { '-isOpen': _ctx.isOpen }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choices, (choice, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ef-select__choice",
          key: index,
          onClick: ($event: any) => (_ctx.selectChoice(choice))
        }, _toDisplayString(choice.name), 9, _hoisted_3))
      }), 128))
    ], 2)
  ]))
}